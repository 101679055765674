<!-- 商品详情 -->
<template>
  <div class="product center-wrap">
    <div class="empty" v-if="errMessage">
      <p>{{errMessage}}</p>
    </div>
    <div class="titlebox" v-if="product">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('pro.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item v-for="(item,index) in product.breadcrumbs" :key="index">{{item.name}}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="big-box" v-if="product">
      <div class="attribute">
        <div class="photo-box">
          <img :src="currentImages" />
          <div>
            <img
              :src="i"
              v-for="(i,index) in product.images_arr"
              :key="index"
              class="click"
              @click.stop="currentImages=i"
            />
          </div>
        </div>
        <!-- 规格属性 -->
        <div class="money-box">
          <!-- 商品标题 -->
          <h1 v-text="product.name"></h1>
          <!-- 商品副标题 -->
          <div class="subheading" v-text="product.title" v-if="product.title"></div>
          <!-- 商品基本参数 -->
          <div class="book-people">
            <div v-for="item in product.base_info_list" :key="item.id">
              <p class="book-title">{{item.name}}</p>
              <p class="introduce">{{item.value | showBaseInfoText}}</p>
            </div>
            <!-- 销量 -->
            <div>
              <p class="book-title" v-text="$t('two.sales')">销量</p>
              <p class="introduce">{{product.sale_number}}</p>
            </div>
          </div>

          <div class="book-money" v-if="product.goods_specs_show">
            <!-- VIP 价格-->
            <!-- <div v-if="product.goods_specs_show.price_vip">
              <h2 v-text="$t('pro.price')">价格</h2>
              <p class="red-text">
                <span>{{product.goods_specs_show.price_type==1?'¥':'HK$'}}</span>
                {{common.showPrice(product.goods_specs_show.price_vip,product.goods_specs_show.price_type,1)}}
              </p>
              <p
                class="add-text"
              >{{common.showPrice(product.goods_specs_show.original_price,product.goods_specs_show.price_type)}}</p>
            </div>-->
            <!-- 人民币/港币 -->
            <!-- <div v-else>
              <h2 v-text="$t('pro.price')">价格</h2>
              <p class="red-text">
                <span>{{product.goods_specs_show.price_type==1?'¥':'HK$'}}</span>
                {{common.showPrice(product.goods_specs_show.price,product.goods_specs_show.price_type,1,true)}}
              </p>
              <p
                class="add-text"
              >{{common.showPrice(product.goods_specs_show.original_price,product.goods_specs_show.price_type)}}</p>
            </div>-->

            <div>
              <h2 v-text="$t('pro.price')">价格</h2>
              <p class="red-text">
                <!-- <span>{{product.goods_specs_show.price_type==1?'¥':'HK$'}}</span> -->
                {{common.showDiscountPrice(product,userinfo)}}
              </p>
              <p
                class="add-text"
              >{{common.showPrice(product.goods_specs_show.original_price,product.goods_specs_show.price_type)}}</p>
            </div>

            <!-- 商品满减优惠 -->
            <div v-if="product.goods_discount" class="product-discount-list-box">
              <h2 v-text="$t('pro.discount')" style="padding-top: 6px;align-self: flex-start;">优惠</h2>
              <div class="discount-list-content">
                <div v-if="product.goods_discount.type== 1">{{product.goods_discount.title}}</div>
                <div
                  v-if="product.goods_discount.type== 2"
                  v-for="item in product.goods_discount.list"
                  :key="item.id"
                >{{item.min + '-' + item.max + '本 '+item.rebate+'折' | showRebate}}</div>
              </div>
            </div>
          </div>

          <div class="express">
            <div v-if="product.service_arr">
              <h2 v-text="$t('pro.service')">服务</h2>
              <p class="introduce" v-text="product.service_arr.toString()">本商品由顺丰发货并提供售后服务</p>
            </div>
            <div v-if="product.goods_specs_show">
              <h2 v-text="$t('pro.isbn')">ISBN码</h2>
              <p class="introduce" v-text="product.goods_specs_show.isbn">219381</p>
            </div>
            <div v-if="product.goods_specs_show">
              <h2 v-text="$t('pro.freight')">运费</h2>
              <p
                class="introduce freight"
                v-if="product.goods_specs_show.freight_discount_name_arr"
              >
                {{product.goods_specs_show.freight_discount_name_arr.money}}
                <span
                  v-if="product.goods_specs_show.freight_discount_name"
                ></span>
                {{product.goods_specs_show.freight_discount_name}}
              </p>
            </div>
          </div>
          <!-- 规格属性选择 -->
          <div class="size-box" v-if="product.all_goods_attr && product.all_goods_attr.length">
            <div v-for="item in product.all_goods_attr" :key="item.id">
              <h2 v-text="item.name" style="padding-bottom: 8px;"></h2>
              <div class="size-box-val">
                <div
                  class="click"
                  :title="isDisable(attrValue.id,attrValue.attribute_id)?'库存不足':''"
                  :class="{
                                    'have-choice':attrValueHasSelect(attrValue) == true,
                                    'no-choice':attrValueHasSelect(attrValue) == false,
                                    'disable':isDisable(attrValue.id,attrValue.attribute_id)
                                    }"
                  v-for="(attrValue,index) in item.item"
                  :key="index"
                  @click.stop="selectAttrValue(attrValue)"
                >
                  {{attrValue.name}}
                  <img
                    src="../assets/icon/have-choice.png"
                    v-if="attrValueHasSelect(attrValue)"
                  />
                </div>
              </div>
            </div>
            <div>
              <h2 v-text="$t('pro.number')" style="padding-bottom: 8px;">数量</h2>
              <div class="reduce-add">
                <img
                  src="../assets/icon/reduce-botton.png"
                  class="click"
                  @click.stop="changeNumber('sub')"
                />
                <!-- <p v-text="number">1</p> -->
                <input type name v-model="number" maxlength="10" />
                <img
                  src="../assets/icon/add-botton.png"
                  class="click"
                  @click.stop="changeNumber('add')"
                />
              </div>
            </div>
          </div>
          <div class="shopping-box">
            <!-- <div class="go-shopping click" @click.stop="toOrderConfirmPage">{{product.goods_specs_show.stock>0?$t('search.buy'):$t('pro.stock-empty')}}</div> -->
            <div class="go-shopping click" @click.stop="toOrderConfirmPage">{{$t('search.buy')}}</div>
            <div class="car-shopping">
              <img src="../assets/icon/cart-product.png" />
              <p class="click" v-text="$t('pro.add_cart')" @click.stop="joinCart">加入购物车</p>
            </div>
          </div>
        </div>
        <!-- 综合图书推荐 -->
        <div
          class="recommend"
          v-if="product.relation_goods_list && product.relation_goods_list.length"
        >
          <div class="recommend-title">
            <div></div>
            <p v-text="$t('pro.comprehensive')">综合图书</p>
            <div></div>
          </div>
          <swiper
            :options="swiperOption"
            ref="mySwiper"
            @slideChangeTransitionEnd="slideChangeTransitionEnd"
          >
            <swiper-slide v-for="(arr,index) in product.relation_goods_list" :key="index">
              <div class="wares" v-for="item in arr" :key="item.id">
                <div class="book-photo click">
                  <img :src="item.image" @click.stop="toProductDetailPage(item.id)" />
                  <p>{{item.name}}</p>
                </div>
                <p v-if="item.goods_specs_show.price_vip">
                  {{common.showPrice(item.goods_specs_show.price_vip,item.priceType)}}
                  <span>{{common.showPrice(item.goods_specs_show.original_price,item.priceType)}}</span>
                </p>
                <p v-else>
                  {{common.showPrice(item.goods_specs_show.price,item.priceType)}}
                  <span>{{common.showPrice(item.goods_specs_show.original_price,item.priceType)}}</span>
                </p>
                <div class="go-look click">
                  <img
                    src="../assets/icon/shopping-car.png"
                    @click.stop="fnShowChooseSpec(item,'cart')"
                  />
                  <p @click.stop="fnShowChooseSpec(item,'buy')">{{$t('search.buy')}}</p>
                </div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="paging">
            <span class="pre" @click.stop="changeSwiperPage('pre')"></span>
            <p>{{swiperPage+1}}</p>
            <span class="next" @click.stop="changeSwiperPage('next')"></span>
          </div>
        </div>
      </div>
      <!-- 商品详情 -->
      <div class="text-details">
        <p class="details-title" v-text="$t('pro.detail')">商品详情</p>
        <div v-html="product.detail" class="html-box"></div>
      </div>
    </div>
    <v-choose-spec
      v-if="showChooseSpec"
      @fnCancelChoose="showChooseSpec=false"
      :product="chooseProduct"
      :showChooseSpecType="showChooseSpecType"
    ></v-choose-spec>
  </div>
</template>
<script>
import { Breadcrumb, BreadcrumbItem } from 'element-ui'
import chooseSpec from '../components/chooseSpec.vue'
import 'swiper/dist/css/swiper.css'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  name: 'Product',
  data() {
    return {
      errMessage: null,
      swiperPage: 0,
      swiperOption: {
        initialSlide: 0,
        loop: false,
      },
      showChooseSpecType: null,
      showChooseSpec: false,
      chooseProduct: null,
      userinfo: this.common.getUserInfo(),
      currentImages: null,
      currentAttrValueList: [],
      product: null,
      number: 1,
      allSets: [],
    }
  },
  created() {
    this.httpGetProductDetail(this.$route.query.product_id)
  },
  methods: {
    slideChangeTransitionEnd: function () {
      this.swiperPage = this.$refs.mySwiper.swiper.activeIndex
    },
    changeSwiperPage: function (type) {
      if (type == 'next') this.$refs.mySwiper.swiper.slideNext()
      if (type == 'pre') this.$refs.mySwiper.swiper.slidePrev()
    },
    fnShowChooseSpec: function (product, type) {
      this.chooseProduct = product
      this.showChooseSpecType = type
      this.showChooseSpec = true
    },
    toProductDetailPage(product_id) {
      // this.$router.push({
      //     path: "/product",
      //     query: {
      //         product_id: product_id
      //     }
      // })

      let routeData = this.$router.resolve({
        path: '/product',
        query: {
          product_id: product_id,
        },
      })
      window.open(routeData.href, '_blank')
    },
    /* 检查产品线是否可以购买 */
    checkGoodsStatus: function (ids, fn) {
      var self = this
      self.common
        .httpPost('/api/order/checkGoodsStatus', {
          ids: ids,
        })
        .then((ret) => {
          if (ret.code == 1) {
            fn && fn()
          } else self.common.showMessage(ret.msg, 'error')
        })
    },
    /* 立即购买*/
    toOrderConfirmPage() {
      var self = this
      if (self.product.goods_specs_show.stock < self.number)
        return self.common.showMessage(self.$t('pro.stock-empty'), 'error')

      if (
        self.currentAttrValueList.length == self.product.all_goods_attr.length
      ) {
        self.checkGoodsStatus(self.product.goods_specs_show.id, function () {
          self.$router.push({
            path: '/orderConfirm',
            query: {
              params: JSON.stringify([
                {
                  specs_id: self.product.goods_specs_show.id,
                  number: self.number,
                },
              ]),
              cart_id: '',
            },
          })
        })
      } else this.common.showMessage(self.$t('pro.size'), 'error') //请选择规格
    },
    /**
     * 加入购物车
     */
    joinCart() {
      var self = this
      if (self.product.goods_specs_show.stock < self.number)
        return self.common.showMessage(self.$t('pro.stock-empty'), 'error')
      if (
        self.currentAttrValueList.length == self.product.all_goods_attr.length
      ) {
        self.checkGoodsStatus(self.product.goods_specs_show.id, function () {
          self.common
            .httpPost('/api/cart/addCart', {
              goods_id: self.product.id,
              specs_id: self.product.goods_specs_show.id,
              number: self.number,
            })
            .then((ret) => {
              if (ret.code == 1) {
                self.common.showMessage(self.$t('pro.add_cart_yes'), 'success') //加入购物车成功
                eventBus.$emit('RefreshCartNumber')
              }
            })
        })
      } else self.common.showMessage(self.$t('pro.size'), 'error') //请选择规格
    },
    /**
     * 获取商品详情
     * @return {[type]} [description]
     */
    httpGetProductDetail(id) {
      var self = this
      if (!id) {
        this.common.showMessage(self.$t('pro.error'), 'error') //发生异常,请重试
        this.$router.push('/')
        return
      }
      this.common
        .httpPost('/api/ajax/getGoodsDetail', {
          id: id,
        })
        .then((ret) => {
          if (ret.code == 1) {
            this.product = ret.data
            this.currentImages = ret.data.images_arr[0]
            self.countALLAttrItemDisable()
            self.errMessage = null
          } else {
            self.errMessage = ret.msg
          }
        })
    },

    isDisable: function (id, attribute_id) {
      var idarr = [id]
      for (var i = 0; i < this.currentAttrValueList.length; i++) {
        if (
          this.currentAttrValueList[i].id == id ||
          this.currentAttrValueList[i].attribute_id == attribute_id
        ) {
          continue
        }
        idarr.push(this.currentAttrValueList[i].id)
      }
      idarr = this.arrSort(idarr)

      for (var i = 0; i < this.allSets.length; i++) {
        var item = this.allSets[i]
        item = this.arrSort(item)
        if (item.toString() == idarr.toString()) {
          return false
        }
      }
      return true
    },

    arrSort: function (arr) {
      var len = arr.length
      for (var i = 0; i < len - 1; i++) {
        for (var j = 0; j < len - 1 - i; j++) {
          // 相邻元素两两对比，元素交换，大的元素交换到后面
          if (arr[j] > arr[j + 1]) {
            var temp = arr[j]
            arr[j] = arr[j + 1]
            arr[j + 1] = temp
          }
        }
      }
      return arr
    },

    /* 计算当前商品的属性值可点击的状态*/
    countALLAttrItemDisable: function () {
      var specs_detail = this.product.specs_detail
      for (var i = 0; i < specs_detail.length; i++) {
        var item = specs_detail[i]
        var allSets = this.powerset(item.item_id)
        // console.log(allSets);
        for (var j = 0; j < allSets.length; j++) {
          this.allSets.push(allSets[j])
        }
      }
    },

    powerset: function (arr) {
      var ps = [[]]
      for (var i = 0; i < arr.length; i++) {
        for (var j = 0, len = ps.length; j < len; j++) {
          ps.push(ps[j].concat(arr[i]))
        }
      }
      return ps
    },
    /**
     * 获取属性的库存
     */
    getAttrItemStock: function (attrItemId, attrId) {
      // 当前已经选择的属性值
      var currentAttrValueList = this.currentAttrValueList
      var specs_detail = this.product.specs_detail

      if (currentAttrValueList.length == 0) {
        for (var i = 0; i < specs_detail.length; i++) {
          var item = specs_detail[i]
          if (item.item_id.indexOf(attrItemId) >= 0 && item.stock > 0)
            return '可以点'
        }
        return '不能点'
      } else {
        var isChange = false // 是否改变
        for (var z = 0; z < this.product.specs_detail.length; z++) {
          var item = this.product.specs_detail[z]
          var temp = 0
          for (var j = 0; j < this.currentAttrValueList.length; j++) {
            var selectAttrItem = this.currentAttrValueList[j]
            if (item.item_id.indexOf(selectAttrItem.id) >= 0) {
              temp++
            }
          }

          if (temp == this.currentAttrValueList.length) {
            if (temp == 2) {
              console.log(temp)
            }
            if (item.item_id.indexOf(attrItemId) >= 0 && item.stock > 0)
              return '可以点'
          } else {
            isChange = true
            continue
          }
        }
        // return "不能点";
      }

      // 判断已经选择的属性值也需要再当前规格属性值里面
      // for (var j = 0; j < currentAttrValueList.length; j++) {
      //     var selectAttrItem = currentAttrValueList[j];
      //     if (selectAttrItem.attribute_id == attrId) return "不改变";
      // }

      // var specs_detail = this.product.specs_detail;
      // for (var i = 0; i < specs_detail.length; i++) {
      //     var item = specs_detail[i];

      //     if (currentAttrValueList.length == 0) {
      //         if (item.item_id.indexOf(attrItemId) >= 0 && item.stock > 0) return "可以点";
      //     }

      //     // if (currentAttrValueList.length > 0) {

      //     // } else {
      //     //     if (item.item_id.indexOf(attrItemId) >= 0 && item.stock > 0) return "可以点";
      //     // }

      //     // var temp = 0;
      //     // for (var j = 0; j < currentAttrValueList.length; j++) {
      //     //     var selectAttrItem = currentAttrValueList[j];
      //     //     if (item.item_id.indexOf(selectAttrItem.id) >= 0) {
      //     //         temp++;
      //     //     }
      //     // }

      //     // if (temp == currentAttrValueList.length) {
      //     //     // 能满足已经选择的属性
      //     //     if (item.item_id.indexOf(attrItemId) >= 0 && item.stock > 0) return "可以点";
      //     // }

      //     // if (currentAttrValueList.length == 2) {
      //     //     console.log(temp);
      //     //     console.log(attrItemId);
      //     //     console.log(currentAttrValueList);
      //     // }

      //     // // temp = true 说明条件已经满足了已经选择的属性
      //     // if (temp) {

      //     // } else {
      //     //     return "不改变";
      //     // }

      //     // if (temp) {
      //     //     if (item.item_id.indexOf(attrItemId) >= 0 && item.stock > 0) return true;
      //     // }
      // }
      // return "不能点";
    },
    /**
     * 修改数量
     */
    changeNumber(type) {
      if (type == 'sub') {
        if (this.number > 1) this.number--
      }
      if (type == 'add') {
        this.number++
      }
    },
    /**
     * 判断attrValue 是否选中
     * @param  {[type]} attrValue [description]
     * @return {[type]}           [description]
     */
    attrValueHasSelect(attrValue) {
      var index = -1
      for (var i = 0; i < this.currentAttrValueList.length; i++) {
        var item = this.currentAttrValueList[i]
        if (item.id == attrValue.id) {
          index = i
          break
        }
      }
      return index >= 0
    },
    /**
     * 选择属性值
     * @param  {[type]} attrValue [description]
     * @return {[type]}           [description]
     */
    selectAttrValue(attrValue) {
      if (this.isDisable(attrValue.id, attrValue.attribute_id)) return

      var index = -1
      var attr_index = -1
      for (var i = 0; i < this.currentAttrValueList.length; i++) {
        var item = this.currentAttrValueList[i]
        // 判断该属性值是否选中了其他值
        if (
          item.attribute_id == attrValue.attribute_id &&
          item.id != attrValue.id
        ) {
          attr_index = i
        }
        if (item.id == attrValue.id) {
          index = i
          break
        }
      }

      if (index >= 0) {
        // 属性值存在
        this.currentAttrValueList.splice(index, 1)
      } else {
        // 属性值不存在
        if (attr_index >= 0) {
          this.currentAttrValueList.splice(attr_index, 1)
        }
        this.currentAttrValueList.push(attrValue)
      }

      if (
        this.currentAttrValueList.length == this.product.all_goods_attr.length
      ) {
        this.getSpecInfo()
      }

      this.countALLAttrItemDisable()
    },

    /**
     * 获取规格信息
     */
    getSpecInfo() {
      var self = this
      var attributes = []
      this.currentAttrValueList.forEach((item) => {
        attributes.push(item.id)
      })
      attributes = this.arrSort(attributes)

      var all_goods_specs = this.product.all_goods_specs
      for (var i = 0; i < all_goods_specs.length; i++) {
        var specsItem = all_goods_specs[i]
        var attr_item = specsItem.attr_item
        attr_item = this.arrSort(attr_item)
        if (attr_item.toString() == attributes.toString()) {
          self.$set(this.product, 'goods_specs_show', specsItem)
          self.currentImages = specsItem.image
        }
      }
    },
    toHomePage() {
      this.$router.push('/')
    },
  },
  watch: {
    $route(to, from) {
      this.httpGetProductDetail(this.$route.query.product_id)
    },
    number: function () {
      if (this.number) {
        this.number = String(this.number).replace(/[^\d]/g, '')
      } else {
        this.number = 1
      }
      // console.log(this.number);
      // if (!this.number || this.number == '') this.number = 1;
      // else this.number = this.number.replace(/[^\d]/g, '')
    },
  },
  components: {
    swiper,
    swiperSlide,
    'el-breadcrumb': Breadcrumb,
    'el-breadcrumb-item': BreadcrumbItem,
    'v-choose-spec': chooseSpec,
  },
  filters: {
    showBaseInfoText: function (value) {
      if (value.length <= 20) return value
      else return value.substring(0, 20)
    },
    showRebate: function (value) {
      return value.replace('.', '')
    },
  },
}
</script>
<style>
.text-details .html-box img {
  margin: 0 auto;
  max-width: 100%;
}
</style>
<style scoped>
.product {
  min-height: 500px;
}

.titlebox {
  height: 40px;
  background: rgba(247, 246, 246, 1);
  padding-left: 83px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.titlebox img {
  height: 12px;
  width: 12px;
  margin-left: 13px;
  margin-right: 13px;
}

.titlebox p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 22px;
}

.big-box {
  margin-left: 83px;
  margin-right: 83px;
}

.attribute {
  margin-top: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.photo-box {
  margin-right: 40px;
}

.photo-box > img {
  height: 318px;
  width: 295px;
  -o-object-fit: contain;
  object-fit: contain;
}

.photo-box div {
  margin-top: 11px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.photo-box div img {
  height: 60px;
  width: 60px;
  margin-right: 5px;
  -o-object-fit: contain;
  object-fit: contain;
}

.photo-box div img:last-child {
  margin-right: 0px;
}

.money-box {
  width: 573px;
}

h1 {
  font-size: 22px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 24px;
  margin-bottom: 7px;
  word-break: break-all;
  text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  /* -webkit-line-clamp: 2; */
  overflow: hidden;
}

.subheading {
  text-align: left;
  font-size: 16px;
  color: #297fab;
  margin-bottom: 7px;
}

.book-people {
  margin-top: 18px;
  margin-bottom: 5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  flex-flow: row wrap;
}

.book-people div {
  margin-right: 40px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 40%;
  margin-bottom: 15px;
}

.book-title {
  font-size: 14px;
  font-weight: 400;
  color: rgba(153, 153, 153, 1);
  line-height: 16px;
  margin-right: 10px;
  width: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.introduce {
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 14px;
}

.introduce.freight {
  display: flex;
  align-items: center;
}

.introduce.freight span {
  height: 13px;
  width: 1px;
  background-color: #979797;
  margin: 0 10px;
}

.book-money {
  /* height: 87px; */
  background: rgba(247, 246, 246, 1);
  padding: 20px 20px 5px 20px;
}

.book-money > div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

h2 {
  width: 80px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 14px;
}

.red-text {
  font-size: 36px;
  color: rgba(225, 36, 27, 1);
  line-height: 33px;
  font-family: 'PingFangSC-Medium', sans-serif;
}

.red-text span {
  font-size: 24px;
  font-weight: bold;
  color: rgba(225, 36, 27, 1);
  line-height: 33px;
  letter-spacing: 1px;
}

.add-text {
  font-size: 16px;
  color: rgba(153, 153, 153, 1);
  line-height: 19px;
  text-decoration: line-through;
  font-family: 'PingFangSC-Medium', sans-serif;
  margin-left: 11px;
  padding-top: 10px;
}

.express {
  padding: 10px 20px 5px 20px;
  border-bottom: 1px dashed rgba(151, 151, 151, 0.7);
}

.express div {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 15px;
}

.size-box {
  padding: 20px 20px 12px 20px;
}

.size-box > div {
  margin-bottom: 20px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.size-box-val {
  flex: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  flex-flow: row wrap;
}

.size-box > div div {
  margin-bottom: 5px;
}

.size-box div.disable {
  background-color: #efefef;
  color: #999;
  cursor: text;
  cursor: pointer;
}

.no-choice {
  border: 1px solid rgba(204, 204, 204, 0.7);
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(102, 102, 102, 1);
  line-height: 30px;
  text-align: center;
  margin-right: 20px;
}

.have-choice {
  background: #effaff;
  border: 1px solid rgba(41, 127, 171, 0.7);
  background-size: 100% 100%;
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 30px;
  text-align: center;
  margin-right: 20px;
  position: relative;
}

.have-choice img {
  height: 15px;
  width: 15px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.no-size {
  background: rgba(244, 244, 244, 1);
  padding: 0px 10px;
  font-size: 14px;
  font-weight: 400;
  color: rgba(179, 179, 179, 1);
  line-height: 30px;
  text-align: center;
  margin-right: 20px;
}

.reduce-add {
  border: 1px solid rgba(230, 230, 230, 0.7);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.reduce-add p,
.reduce-add input {
  height: 30px;
  width: 50px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 30px;
  text-align: center;
  border-left: 1px solid rgba(230, 230, 230, 0.7);
  border-right: 1px solid rgba(230, 230, 230, 0.7);
}

.reduce-add img {
  height: 18px;
  width: 18px;
  margin: 6px;
}

.shopping-box {
  margin-left: 100px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.go-shopping {
  background: rgba(41, 127, 171, 1);
  border-radius: 2px;
  padding-left: 28px;
  padding-right: 28px;
  font-size: 16px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 40px;
  margin-right: 20px;
}

.car-shopping {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 16px;
  padding-right: 16px;
  border-radius: 2px;
  border: 1px solid rgba(41, 127, 171, 0.7);
  background: rgba(239, 250, 255, 1);
}

.car-shopping img {
  height: 25px;
  width: 25px;
  margin-right: 5px;
}

.car-shopping p {
  font-size: 16px;
  font-weight: 400;
  color: rgba(41, 127, 171, 1);
  line-height: 38px;
}

.recommend {
  width: 182px;
  margin-left: 55px;
}

.recommend-title {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.recommend-title p {
  font-size: 14px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 18px;
}

.recommend-title div {
  width: 50px;
  height: 1px;
  background: #b4b4b4;
}

.wares {
  height: 214px;
  width: 150px;
  margin: 15px 16px 20px 16px;
}

.book-photo {
  height: 150px;
  width: 150px;
  position: relative;
}

.book-photo p {
  width: 142px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  line-height: 20px;
  text-align: center;
  background: rgba(153, 153, 153, 0.8);
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-left: 4px;
  padding-right: 4px;
  position: absolute;
  bottom: 0px;
}

.book-photo img {
  height: 150px;
  width: 150px;
}

.wares > p {
  width: 150px;
  font-size: 16px;
  color: #e1241b;
  line-height: 21px;
  margin-top: 9px;
  text-align: center;
}

.wares p span {
  font-size: 16px;
  color: #999999;
  line-height: 21px;
  margin-left: 6px;
  text-decoration: line-through;
}

.go-look {
  width: 96px;
  height: 23px;
  border-radius: 12px;
  border: 1px solid rgba(156, 192, 210, 0.7);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 8px auto 0;
}

.go-look img {
  height: 15px;
  width: 15px;
  margin: 4px 5px 4px 9px;
}

.go-look p {
  padding-left: 8px;
  padding-right: 8px;
  line-height: 23px;
  color: #9cc0d2;
  font-size: 12px;
  border-left: 1px solid rgba(156, 192, 210, 0.7);
}

.text-details {
  display: grid;
  border: 1px solid rgba(182, 182, 182, 0.7);
  margin: 50px 0px 83px 0px;
}

.details-title {
  padding-left: 30px;
  font-size: 16px;
  font-weight: 500;
  color: rgba(153, 153, 153, 1);
  line-height: 50px;
  background: rgba(247, 246, 246, 1);
}

h3 {
  font-size: 18px;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 28px;
  text-align: center;
  margin-bottom: 10px;
}

.text-details div {
  margin: 20px;
}

.text-details div p {
  font-size: 14px;
  font-weight: 400;
  color: rgba(51, 51, 51, 1);
  line-height: 28px;
}

.details-photo {
  height: 430px;
  width: 1198px;
}

.paging {
  display: flex;
  align-items: center;
  justify-content: center;
}

.paging p {
  width: 27px;
  color: #999999;
  font-size: 15px;
  text-align: center;
}

.paging span:last-child {
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-color: #cccccc transparent transparent transparent;
  margin-top: 8px;
}

.paging span.pre:hover {
  border-color: transparent transparent transparent #297fab;
  cursor: pointer;
}

.paging span.next:hover {
  border-color: #297fab transparent transparent transparent;
  cursor: pointer;
}

.paging span:first-child {
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent transparent #cccccc;
  transform: rotate(270deg);
  margin-bottom: 8px;
}

.book-money .product-discount-list-box {
  display: flex;
  flex-flow: row wrap;
}

.book-money .product-discount-list-box div {
  margin-right: 10px;
}

.discount-list-content {
  flex: 1;
  display: flex;
  flex-flow: row wrap;
}

.book-money .discount-list-content div {
  height: 24px;
  padding-left: 8px;
  padding-right: 8px;
  background: rgba(255, 255, 255, 1);
  border-radius: 1px;
  border: 1px solid rgba(225, 36, 27, 0.7);
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: rgba(225, 36, 27, 1);
  line-height: 24px;
  margin-bottom: 8px;
}
</style>